.PeopleDashboard {
    padding: 15px;
}

.PeopleDashboard-new-person {
    padding-bottom: 15px;
}

.PeopleDashboard-person-list {
    padding-top: 15px;
}