.AddPerson {
    padding: 15px;
}

.AddPerson-submit {
    padding-top: 15px;
}

.AddPerson-cancel {
    padding-left: 15px;
}