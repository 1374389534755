.App-login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-header {
  padding: 5px;
  display: flex;
  background-color: rgb(12, 41, 61);
}

.App-header-space {
  flex-grow: 1
}

.App-link {
  color: #61dafb;
}

.validation-error {
  visibility: hidden;
  background: white;
  color: red;
  height: 20px;
}

.show-errors .validation-error {
  visibility: visible;
}