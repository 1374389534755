.PersonEvent {
    padding: 10px;
}

.PersonEvent .row {
    margin-bottom: 0px;
}

.PersonEvent.odd {
    background-color: lightgray;
}